/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-top: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -1px;
    left: 27px;
    height: 10px;
    width: 10px;
    background-color: #eee;
    border: 2px solid #ccc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
    border: none;
    width: 14px;
    height: 14px;
}
.container input:checked ~ .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 3px;
    top: 0px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}




.item-enter {
    /*opacity: 0;*/
    /*transform: scale(0, 1)*/
    width: 0%;
    height: 0;
    overflow: hidden;
}
.item-enter-active {
    width: 100%;
    height: 280px;
    overflow: hidden;
    /*opacity: 1;*/
    /*transform: scale(1, 1);*/
    transition: height 300ms ease-in, width 300ms ease-in;
}

.item-exit {
    /*opacity: 1;*/
    height: 280px;
    width: 100%;
    overflow: hidden;
}
.item-exit-active {
    /*opacity: 0;*/
    overflow: hidden;
    width: 0%;
    height: 0;
    transition: height 300ms ease-in, width 300ms ease-in;
}

@keyframes circle{
    0%{
        transform:rotate(0deg)
        /*rotate(0deg);*/

    }
    50%{
        transform:rotate(180deg)
        /*rotate(180deg);*/
    }
    100%{
        transform:rotate(360deg)
        /*rotate(360deg);*/
    }
}



.settings-enter {
    animation: circle 1s infinite;
}

.settings-active {
    animation: circle 1s infinite;
}

.settings-exit {
    animation: circle 1s infinite;
}

.settings-exit-active {
    animation: circle 1s infinite;
}